import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account/account.service';
import { AddUserDialogComponent } from 'src/app/shared/components/add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-linked-vendors-list',
  templateUrl: './linked-vendors-list.component.html',
  styleUrls: ['./linked-vendors-list.component.scss']
})
export class LinkedVendorsListComponent implements OnInit {
  account = localStorage.getItem('HechRomeoAccount');
  admin: boolean = localStorage.getItem('HechRomeoRole') == 'Manager';
  searchValue: string;
  vendorsList = [];
  userCreate: any;
  userToDelete: any;
  availableUsers = [];
  accountEmail: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.userToDelete = {};

    this.accountService.getVendorData().subscribe(res => {
      this.accountEmail = res.email;
      this.resetCreateUser();
      this.getVendorsList();
    });
  }

  getAllUsers() {
    this.accountService.getAllUsers().subscribe(res => {
      if (this.vendorsList.length > 0) {
        this.vendorsList.forEach(item => {
          res.users = res.users.filter(user => user.email !== item.email);
          this.availableUsers = res.users;
        });
      } else {
        this.availableUsers = res.users;
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      data: {
        title: 'Aggiungi un fornitore',
        user: this.userCreate,
        isVendor: false,
        availableUsers: this.availableUsers
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveUser();
      }
    });
  }

  deleteUser() {
    this.accountService.deleteLinkedAccount(this.accountEmail, this.userToDelete.account).subscribe(res => {
      this.userToDelete = {};
      this.getVendorsList();
    });
  }

  getVendorsList() {
    this.vendorsList = [];

    this.accountService.getLinkedVendors(this.accountEmail).subscribe(res => {
      this.vendorsList = res.users;

      this.getAllUsers();
    });
  }

  resetCreateUser(): void {
    this.userCreate = {};
    this.userCreate.email = this.accountEmail;
  }

  saveUser() {
    this.accountService.createAccountUser(this.userCreate).subscribe(res => {
      this.resetCreateUser();
      this.getVendorsList();
    }, err => {
      console.log(err);
    });
  }
}
