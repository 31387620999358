<form>
  <label for="password">Password</label>
  <input class="form-control" id="passwordOutput" name="password" #passwordOutput value="{{newPassword}}" />

  <label class="mt-3" for="psw-length">{{ "Lunghezza Password" | translate }}</label>
  <div class="d-flex justify-content-start align-items-center">
    <input type="range" name="psw-length" (change)="updatePasswordLength($event)" min="4" max="32" step="1" value="{{passwordLenght}}" />
    <h2 class="ml-2 px-3">{{passwordLenght}}</h2>
  </div>

  <label class="mt-3" for="psw-length">{{ "Caratteri da usare" | translate }}</label>
  <div class="d-flex justify-content-between">
    <span *ngFor="let checkbox of checkboxes" class="d-flex align-items-center checkbox">
      <input type="checkbox" (change)="updateCheckboxValue($event)" id="{{checkbox.id}}" [checked]="checkbox.checked" />
      <label [ngClass]="{'text-lowercase': checkbox.label === 'a-z'}" class="ml-2 mb-0" for="{{checkbox.id}}">{{checkbox.label}}</label>
    </span>
  </div>

  <button type="button" class="btn btn-primary mt-4 w-100" (click)="generatePassword()">{{buttonLabel}}</button>
</form>
