import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './pages/categories/list/list.component';
import { LoginComponent } from './pages/account/login/login.component';
import { RegisterComponent } from './pages/account/register/register.component';
import { LayoutComponent } from './components/layout/layout.component';
import { VendorListComponent } from './pages/admin/vendor-list/vendor-list.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { CalendarComponent } from './pages/calendar/calendar-reservations/calendar.component';
import { AllowanceGuard } from './guards/allowance.guard';
import { SettingsComponent } from './pages/account/settings/settings.component';
import { AccountInfoComponent } from './pages/account/account-info/account-info.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { ManageCategoriesVariablesComponent } from './pages/admin/manage-categories-variables/manage-categories-variables.component.component';
import { AdminListComponent } from './pages/account/admin-list/admin-list.component';
import { CalendarAdvancedComponent } from './pages/calendar/calendar-advanced/calendar-advanced.component';
import { CalendarReportComponent } from './pages/calendar/calendar-report/calendar-report.component';
import { SignageComponent } from './pages/categories/signage/signage.component';
import { CreateSignageComponent } from './pages/categories/create-signage/create-signage.component';
import { TvChannelsComponent } from './pages/tv/tv-channels/tv-channels.component';
import { ClonesComponent } from './pages/tv/clones/clones.component';
import { FirmwareComponent } from './pages/tv/firmware/firmware.component';
import { TvSettingsComponent } from './pages/tv/tv-settings/tv-settings.component';
import { TvListComponent } from './pages/tv/tv-list/tv-list.component';
import { CloneDetailsComponent } from './pages/tv/clone-details/clone-details.component';
import { FirmwareDetailsComponent } from './pages/tv/firmware-details/firmware-details.component';
import { TvChannelsDetailsComponent } from './pages/tv/tv-channels-details/tv-channels-details.component';
import { TvSettingsDetailsComponent } from './pages/tv/tv-settings-details/tv-settings-details.component';
import { TvDetailsComponent } from './pages/tv/tv-details/tv-details.component';
import { DownloadPdfComponent } from './pages/categories/download-pdf/download-pdf.component';
import { AssistanceComponent } from './pages/assistance/assistance.component';
import { WebServicesComponent } from './pages/web-services/web-services.component';
import { AddWsProjectComponent } from './pages/web-services/add-ws-project/add-ws-project.component';
import { UsersListComponent } from './pages/account/users-list/users-list.component';
import { LinkedVendorsListComponent } from './pages/account/vendors-list/linked-vendors-list.component';
import { UsersManagementComponent } from './pages/admin/users-management/users-management.component';

const routes: Routes = [
  { path: '', redirectTo: 'categoriesAndServices', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'termsOfService', component: TermsOfServiceComponent },
  { path: 'termsOfData', component: TermsOfServiceComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'categoriesAndServices',
        component: ListComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'signage',
        component: SignageComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'editSignage',
        component: CreateSignageComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'vendorList',
        component: VendorListComponent,
        canActivate: [AuthGuard, AdminGuard],
      },
      {
        path: 'users-management',
        component: UsersManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'calendarAdvanced',
        component: CalendarAdvancedComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'calendarReport',
        component: CalendarReportComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'accountInfo',
        component: AccountInfoComponent,
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'manageVariables',
        component: ManageCategoriesVariablesComponent,
        canActivate: [AuthGuard, AdminGuard],
      },
      {
        path: 'admin-list',
        component: AdminListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users-list',
        component: UsersListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'linked-vendors-list',
        component: LinkedVendorsListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tvChannels',
        component: TvChannelsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editChannel',
        component: TvChannelsDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'clones',
        component: ClonesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editClone',
        component: CloneDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'firmware',
        component: FirmwareComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editFirmware',
        component: FirmwareDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tvSettings',
        component: TvSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editTvSettings',
        component: TvSettingsDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tvList',
        component: TvListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'editTv',
        component: TvDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'downloadPdf',
        loadChildren: () => import('./pages/categories/download-pdf/download-pdf.module').then(m => m.DownloadPdfModule),
        canActivate: [AuthGuard, AllowanceGuard],
      },
      {
        path: 'assistance',
        component: AssistanceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'webServices',
        component: WebServicesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'addWsProject',
        component: AddWsProjectComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'addWsProject/:wsProjectId',
        component: AddWsProjectComponent,
        canActivate: [AuthGuard]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
