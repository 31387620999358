import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  admin: boolean = localStorage.getItem('HechRomeoRole') == 'Manager';
  filteredUsers: Observable<any[]>;

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.filteredUsers = this.data.availableUsers;
  }

  isSaveEnabled(): boolean {
    return (this.data.user.account && this.data.user.account !== '')
      && (this.data.user.email && this.data.user.email !== '');
  }

  filterOptions(value) {
    const filterValue = value.toLowerCase();
    if (this.data.isVendor) {
      this.filteredUsers = this.data.availableUsers.filter(user =>
        user.email.toLowerCase().includes(filterValue)
      );
    } else {
      this.filteredUsers = this.data.availableUsers.filter(user =>
        user.account.toLowerCase().includes(filterValue)
      );
    }
  }
}
