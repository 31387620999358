import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountDetailService } from 'src/app/services/account/account-detail.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AdminServiceService } from 'src/app/services/admin/admin-service.service';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit {
  account = localStorage.getItem('HechRomeoAccount');
  usersList = [];
  vendorToDelete;
  searchValue: string;
  accountEmail: string;

  constructor(
    private readonly accountService: AccountService,
    private accountDetailService: AccountDetailService,
    private adminService: AdminServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.searchValue = '';
    if (!localStorage.getItem('OriginalAccount')) {
      this.accountService.getVendorData().subscribe(res => {
        this.accountEmail = res.email;
        this.getUsersList(this.accountEmail);
      });
    } else {
      this.accountService.getOriginalVendorData().subscribe(res => {
        this.accountEmail = res.email;
        this.getUsersList(this.accountEmail);
      });
    }
  }

  getUsersList(email: string) {
    this.accountService.getLinkedUsersForManagement(email).subscribe(res => {
      this.usersList = res;
    });
  }

  impersonate(account: string) {
    if (!localStorage.getItem('OriginalAccount')) {
      localStorage.setItem('OriginalAccount', this.account);
    }
    localStorage.setItem('HechRomeoAccount', account);
    this.accountDetailService.setAccount(account);
    window.location.reload();
  }

  deleteUser(username: string) {
    this.adminService.deleteVendor(username).subscribe(() => {
      this.getUsersList(this.accountEmail);
    });
  }

  infoUser(username: string) {
    localStorage.setItem('HechRomeoAccount', username);
    this.accountDetailService.setAccount(username);
    this.router.navigate(['accountInfo']);
  }
}
