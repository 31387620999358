import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { generateId } from 'src/app/models/notification/notification';
import { Channel, Clone, Frimware, Tv, TvSettings } from 'src/app/models/tv/tv';
import { AccountService } from 'src/app/services/account/account.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-tv-details',
  templateUrl: './tv-details.component.html',
  styleUrls: ['./tv-details.component.scss'],
})
export class TvDetailsComponent implements OnInit {
  id: string = '';
  tv: Tv = new Tv();
  channels: Array<Channel> = [];
  clones: Array<Clone> = [];
  firmwares: Array<Frimware> = [];
  settings: Array<TvSettings> = [];
  tvs: Array<Tv> = [];
  firstMac: string = '';
  authorizedTvs: number;
  admin: boolean;

  constructor(
    private route: ActivatedRoute,
    private tvService: TvService,
    private router: Router,
    private translateService: TranslateService,
    private accountService: AccountService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.admin = localStorage.getItem('HechRomeoRole') == 'Manager' ? true : false;
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) this.getTv();
    });
    this.getChannelsLists();
    this.getClones();
    this.getFirmwares();
    this.getSettings();
    this.getTvs();
    this.getVendorTvManagement();
  }

  getVendorTvManagement() {
    this.accountService.getVendorTvManagement().subscribe((data) => {
      this.authorizedTvs = data.authorizedtvs;
    });
  }

  getTv() {
    this.tvService.getTv(this.id).subscribe((data) => {
      this.tv = data;
      this.firstMac = data.macaddress;
      console.log('this.tv',this.tv);
    });
  }

  getChannelsLists() {
    this.tvService.getChannels().subscribe((data) => {
      this.channels = data;
    });
  }

  getClones() {
    this.tvService.getClones().subscribe((data) => {
      this.clones = data;
    });
  }

  getFirmwares() {
    this.tvService.getFirmwares().subscribe((data) => {
      this.firmwares = data;
    });
  }

  getSettings() {
    this.tvService.getSettings().subscribe((data) => {
      this.settings = data;
    });
  }

  getTvs() {
    this.tvService.getTvs().subscribe((data: Tv[]) => {
      this.tvs = data;
    });
  }

  saveTv() {
      if (this.tv === undefined || this.tv.roomnumber === undefined || this.tv.roomnumber.length <= 0 || this.tv.settingid === undefined
        || this.tv.settingid.length <= 0 || this.tv.macaddress === undefined || this.tv.macaddress.length <= 0) {
          alert('Errore inserire tutte le informazioni');
      } else if ( this.validationMessage ) {
        console.log('errore nel form messaggio')
      } else if (this.tv.checkin && this.tv.checkin.length && this.tv.checkout && this.tv.checkout.length
          && new Date(this.tv.checkin) > new Date(this.tv.checkout)) {
          alert('Errore il checkin deve essere una data inferiore al checkout');
      } else if (
          !this.firstMac && this.tvs.some( (tv: Tv) => tv.macaddress === this.tv.macaddress.trim() ) ||
          this.firstMac && this.firstMac !== this.tv.macaddress.trim() && this.tvs.some( (tv: Tv) => tv.macaddress === this.tv.macaddress.trim() )
         ) {
          alert(this.translateService.instant(`TV_DETAILS_VALIDATIONS.MAC_EXISTING`));
      } else {
        this.id ? this.updateTv() : this.createTv()
      }
  }

  createTv() {
    if (this.authorizedTvs === this.tvs.length) {
      const notification = {
        id: generateId(),
        image: undefined,
        title: 'Error: ',
        body: this.translateService.instant(`Licenze TV Insufficienti`),
      };
      this.notificationService.addNotification(notification);

    } else {
      this.tv.account = localStorage.getItem('HechRomeoAccount');
      this.tvService.createTv(this.tv).subscribe((data) => {
        this.router.navigate(['tvList']);
      });
    }
  }

  updateTv() {
    this.tvService.updateTv(this.tv).subscribe((data) => {
      this.router.navigate(['tvList']);
    });
  }

  archivia(){
    this.tv.nameguest = '';
    this.tv.surnameguest = '';
    this.tv.message = '';
    this.tv.visible = false;
    this.updateTv();
  }

 get resetMsg(): any {
    return {
      nameGuest: '',
      surnameGuest: '',
      message: '',
      isVisible: false
    }
  }

  get validationMessage(){
    const tvMessageArr = Object.values({
      nameGuest: this.tv.nameguest,
      surnameGuest: this.tv.surnameguest,
      message: this.tv.message,
      visible: this.tv.visible
    });
    console.log('tvMessageArr', tvMessageArr);
    const isEveryEmpty = tvMessageArr.every( val => !val);
    const isEveryFull = tvMessageArr.every( val => val);
    if( isEveryEmpty || isEveryFull ) {
      return false
    };

    for (const key in this.tv) {
        const element = this.tv[key];
        if( key !== 'visible' ) {
            switch (key) {
              case 'nameguest':
                if(!element)  {
                  alert(this.translateService.instant(`WELCOME_MSG.INSERIRE_NOME`))
                  return true;
                };
                break;
              case 'surnameguest':
                if(!element)  {
                  alert(this.translateService.instant(`WELCOME_MSG.INSERIRE_COGNOME`))
                  return true;
                };
                break;
              case 'message':
                if(!element)  {
                  alert(this.translateService.instant(`WELCOME_MSG.MESSAGGIO`))
                  return true;
                }
                break;
            }
        }
    }
    return false
  }

  setErrorMessage( key ){

  }

  getPswSoftApi( pswSoftApi: string ){
    this.tv.password = pswSoftApi;
  }
}
