import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {AccountDetailService} from '../../services/account/account-detail.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  admin: boolean =
    localStorage.getItem('HechRomeoRole') == 'Manager' ? true : false;
  listUsers: boolean =
    localStorage.getItem('HechRomeoAccount') ===
    localStorage.getItem('HechRomeoEmail');

  constructor(private router: Router, private translate: TranslateService, public accountDetailService: AccountDetailService) {}

  ngOnInit(): void {}

  logout() {
    localStorage.removeItem('HechRomeoRole');
    localStorage.removeItem('HechRomeoAccount');
    sessionStorage.removeItem('HechRomeoToken');
    localStorage.removeItem('HechRomeoEmail');
    localStorage.removeItem('HechRomeoPassword');
    localStorage.removeItem('OriginalAccount');
    this.accountDetailService.setAccount(undefined);
    this.router.navigate(['login']);
  }
  changeLanguage(language) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }
}
