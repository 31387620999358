import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminVendor } from 'src/app/models/admin-vendor';
import { AccountService } from 'src/app/services/account/account.service';
import { AddUserDialogComponent } from 'src/app/shared/components/add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  account = localStorage.getItem('HechRomeoAccount');
  searchValue: string;
  usersList = [];
  userCreate: any;
  userToDelete: any;
  admin: boolean = localStorage.getItem('HechRomeoRole') == 'Manager';
  availableUsers = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.userToDelete = {};
    this.resetCreateUser();
    this.getUsersList();
  }

  getAllUsers() {
    this.accountService.getAllUsers().subscribe(res => {
      if (this.usersList.length > 0) {
        this.usersList.forEach(item => {
          res.users = res.users.filter(user => user.email !== item.email);
          this.availableUsers = res.users;
        });
      } else {
        this.availableUsers = res.users;
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      data: {
        title: 'Aggiungi un utente',
        user: this.userCreate,
        isVendor: true,
        availableUsers: this.availableUsers
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    this.accountService.createAccountUser(this.userCreate).subscribe(res => {
      this.resetCreateUser();
      this.getUsersList();
    }, err => {
      console.log(err);
    });
  }

  deleteUser() {
    this.accountService.deleteLinkedAccount(this.userToDelete.email, this.account).subscribe(res => {
      this.userToDelete = {};
      this.getUsersList();
    });
  }

  resetCreateUser(): void {
    this.userCreate = {};
    this.userCreate.account = localStorage.getItem('HechRomeoAccount');
  }

  getUsersList(): void {
    this.usersList = [];

    this.accountService.getLinkedUsers(this.account).subscribe(result => {
      this.usersList = result.users;

      this.getAllUsers();
    });
  }
}
