<div class="row mx-0">
    <div class="col-12 my-2">
        <div class="row mx-0 w-100">
            <div class="form-group col-1 p-0">
                <div>
                    <label for="search">{{ "Cerca" | translate }}:</label>
                    <input id="search" [(ngModel)]="searchValue" />
                </div>
            </div>

            <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

            <ng-container *ngIf="admin">
                <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
                    <button data-toggle="modal" data-target="#add-user" class="btn btn-primary mb-4 w-100" (click)="openDialog()">
                        + {{ "Associa utente" | translate }}
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="row mx-0">
            <div class="card w-100">
                <div class="card-body" style="overflow-y: auto">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Account</th>
                                <th scope="col">Email</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let user of usersList | filter: searchValue">
                                <td *ngIf="admin" style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 30%;
                                    ">
                                    {{ user.account }}
                                </td>
                                <td *ngIf="admin" style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 65%;
                                    ">
                                    {{ user.email }}
                                </td>
                                <td *ngIf="!admin" style="
                                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    width: 95%;
                                    ">
                                    {{ user.email }}
                                </td>
                                <td style="
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                    padding: 0;
                                    cursor: pointer;
                                    width: 5%;
                                    ">
                                    <ng-container *ngIf="admin">
                                        <div class="d-flex flex-row justify-content-end w-100">
                                            <div data-toggle="modal" data-target="#delete-admin" class="delete-img" (click)="
                                                    userToDelete.email = user.email;
                                                    userToDelete.account = user.account
                                                ">
                                                <img src="assets/img/Pittogramma%20elimina%20-%20cestino.png"
                                                    style="width: 30px" alt="" />
                                            </div>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="delete-admin" tabindex="-1" role="dialog" aria-labelledby="delete-admin" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "Confermare eliminazione" | translate }}</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                {{
                "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
                | translate
                }}
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ "Annulla" | translate }}
                </button>

                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteUser()">
                    {{ "Conferma" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>