import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete'



@NgModule({
  declarations: [AddUserDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    TranslateModule,
    MatFormFieldModule,
    MatAutocompleteModule
  ],
  exports: [
    AddUserDialogComponent,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class SharedModule { }
