<h2 mat-dialog-title>
    <div class="modal-header">
        <h5 class="modal-title">{{ data.title | translate }}</h5>

        <button type="button" class="close" aria-label="Close" [mat-dialog-close]="false">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</h2>

<mat-dialog-content>
    <div class="modal-body">
        <div *ngIf="admin" class="row mx-0">
            <div class="col-12 form-group">
                <label for="account"> Account </label>

                <ng-container *ngIf="data.isVendor">
                    <input readonly [(ngModel)]="data.user.account" name="account" type="text" class="form-control" id="account"
                        aria-describedby="account" />
                </ng-container>

                <ng-container *ngIf="!data.isVendor">
                    <input [(ngModel)]="data.user.account" name="account" type="text" class="form-control" id="account"
                        aria-describedby="account" [matAutocomplete]="autoAccount" (input)="filterOptions($event.target.value)" />
                        <mat-autocomplete #autoAccount="matAutocomplete">
                            <ng-container *ngFor="let user of filteredUsers">
                                <mat-option [value]="user.account">{{user.account}}</mat-option>
                            </ng-container>
                        </mat-autocomplete>
                </ng-container>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-12 form-group">
                <label for="email"> Email </label>

                <ng-container *ngIf="!data.isVendor">
                    <input [(ngModel)]="data.user.email" name="email" type="text" class="form-control" id="email"
                        aria-describedby="email" />
                </ng-container>

                <ng-container *ngIf="data.isVendor">
                    <input [(ngModel)]="data.user.email" name="email" type="text" class="form-control" id="email"
                        aria-describedby="email" [matAutocomplete]="auto" (input)="filterOptions($event.target.value)" />
                        <mat-autocomplete #auto="matAutocomplete">
                            <ng-container *ngFor="let user of filteredUsers">
                                <mat-option [value]="user.email">{{user.email}}</mat-option>
                            </ng-container>
                        </mat-autocomplete>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>

<div class="modal-footer">
    <mat-dialog-actions class="dialog-buttons">
        <button type="button" class="btn btn-secondary" [mat-dialog-close]="false">
            {{ "Annulla" | translate }}
        </button>

        <button [disabled]="!isSaveEnabled()" type="button" class="btn btn-primary" [mat-dialog-close]="true">
            {{ "Salva" | translate }}
        </button>
    </mat-dialog-actions>
</div>